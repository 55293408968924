import * as app from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAw4aWIgsCJvc9zoISFLiZDMokjJZ2HK_8',
  authDomain: 'sino-72f9d.firebaseapp.com',
  databaseURL: 'https://sino-72f9d.firebaseio.com',
  projectId: 'sino-72f9d',
  storageBucket: '',
  messagingSenderId: '997737582322',
  appId: '1:997737582322:web:3dd501ac304df1570c8a6b',
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.database();
    this.provider = new app.auth.GoogleAuthProvider();
    this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    this.messaging = app.messaging();

    // this.auth.onAuthStateChanged(user => {
    //   if (user) {
    //     console.log(user, "From Firebase");
    //   } else {
    //     console.log("user is Nulll");
    //   }
    // });

    // app.auth().sendSignInLinkToEmail();

    // app.auth().onAuthStateChanged;
  }

  createUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  auth = () => {
    return this.auth;
  };

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) => {
    return this.auth.currentUser.updatePassword(password);
  };

  currentUser = () => this.auth.currentUser;

  idToken = () => this.auth.currentUser.getIdToken(true);

  googleSignIn = () => this.auth.signInWithPopup(this.provider);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      //   url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      url: 'http://localhost:3000',
    });

  currentUserDevices = (uid) =>
    this.db.ref('devices').orderByChild('uid').equalTo(uid);

  singleDeviceRef = (id) => this.db.ref().child('devices').child(id);
}

export default Firebase;
