import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from '@material-ui/pickers';

const MyDatePicker = ({ selectedDate, setSelectedDate, label }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        label={label ? label : 'Select Report Date'}
        value={selectedDate}
        style={{ backgroundColor: '#fff', padding: 4, borderRadius: 4 }}
        onChange={(date) => setSelectedDate(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MyDateTimePicker = ({ selectedDate, setSelectedDate, label }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        disableToolbar
        variant="inline"
        label={label ? label : 'Select Report Date'}
        format="MM/dd/yyyy hh:mm a"
        value={selectedDate}
        style={{
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: 4,
          border: '1px solid #999',
        }}
        onChange={(date) => setSelectedDate(date)}
        ampm={false}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MyDatePicker;
