import { utils, writeFile } from 'sheetjs-style';
// import { utils, writeFileXLSX, CellStyle } from 'xlsx-js-style';

const createHeadersColumn = (row_number, headers) => {
  return headers.map(
    (x, i) => String.fromCharCode(65 + i) + String(row_number)
  );
};

const getColumnWidthofRouteSummeryTable = (datas, headers) => {
  //id	registration_number	chasis_number	model	code	day_count	start_time	end_time	location_count	distance	running_time	congestion_time	idle_time

  let data = [...datas];
  let arr = [
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_address.length),
        headers[0].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_time.length),
        headers[1].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.distance.length),
        headers[2].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.duration.length),
        headers[3].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_time.length),
        headers[4].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_address.length),
        headers[5].length
      ),
    },
  ];

  return arr;
};

const getColumnWidthOfDateRangeReport = (datas, headers) => {
  let data = [...datas];
  let arr = [
    {
      wch: data.reduce((w, r) => Math.max(w, r.date.length), headers[0].length),
    },
    {
      wch: data.reduce((w, r) => Math.max(w), headers[1].length),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start.length),
        headers[2].length
      ),
    },
    {
      wch: data.reduce((w, r) => Math.max(w, r.end.length), headers[3].length),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.running.length),
        headers[4].length
      ),
    },
    {
      wch: data.reduce((w, r) => Math.max(w, r.idle.length), headers[5].length),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.congestion.length),
        headers[6].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.distance.length),
        headers[7].length
      ),
    },
    {
      wch: data.reduce((w, r) => Math.max(w), headers[8].length),
    },
  ];

  return arr;
};

export const downloadRouteSummery = (datas) => {
  const headers = [
    'Start Address',
    'Start At',
    'Distance(KM)',
    'Duration',
    'End At',
    'End Address',
  ];

  const ws = utils.json_to_sheet(datas, { origin: 'A2' });
  ws['!cols'] = getColumnWidthofRouteSummeryTable(datas, headers);

  utils.sheet_add_aoa(ws, [headers], { origin: 'A2' });

  //   ws['A3'].s = {
  //     font: {
  //       name: 'Calibri',
  //       sz: 50,
  //       bold: true,
  //       color: { rgb: 'FF0000' },
  //     },
  //   };

  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, 'Route Summery');
  writeFile(wb, 'Route Summery.xlsx');
};

export const downloadRangeReport = (datas, infoRows) => {
  const headers = [
    'Date',
    'Start Address',
    'Start',
    'End',
    'Running',
    'Idle',
    'Congestion',
    'Distance (KM)',
    'End Address',
  ];

  // const infoRows = [
  //   ['Report Title', 'Sales Report'], // Row 1
  //   ['Generated By', 'John Doe'], // Row 2
  //   ['Date', new Date().toLocaleDateString()], // Row 3
  //   ['Department', 'Finance'], // Row 4
  //   ['', ''], // Row 5 (empty row for spacing)
  // ];

  const ws = utils.json_to_sheet(datas, { origin: 'A10' });
  ws['!cols'] = getColumnWidthOfDateRangeReport(datas, headers);

  utils.sheet_add_aoa(ws, [headers], { origin: 'A10' });

  const sheet = utils.aoa_to_sheet(infoRows);

  Object.assign(sheet, ws);

  const infoHeadersRow = infoRows.map((x, i) => 'A' + (i + 1));

  infoHeadersRow.forEach((cell) => {
    if (sheet[cell]) {
      sheet[cell].s = {
        font: { italic: true, bold: true, size: 14 },
      };
    }
  });

  // sheet['A1'].s = {
  //   font: { bold: true, size: 14 }, // Bold green title
  // };

  // sheet['A2'].s = {
  //   font: { italic: true, bold: true, size: 14 }, // Italic font
  // };

  // sheet['A3'].s = {
  //   font: { bold: true, size: 14 },
  // };

  //   ws['A3'].s = {
  //     font: {
  //       name: 'Calibri',
  //       sz: 50,
  //       bold: true,
  //       color: { rgb: 'FF0000' },
  //     },
  //   };

  let headersCol = createHeadersColumn(10, headers);

  headersCol.forEach((cell) => {
    if (sheet[cell]) {
      sheet[cell].s = {
        font: { bold: true, color: { rgb: 'FFFFFF' }, size: 12 },
        fill: { fgColor: { rgb: '4CAF50' } },
        // alignment: { horizontal: 'center' },
      };
    }
  });

  // sheet['A10'].s = { font: { bold: true } };

  sheet['!cols'] = [
    { wch: 20 }, // Column A
    { wch: 30 }, // Column B
    { wch: 10 }, // Column C
    { wch: 10 }, // Column C
    { wch: 15 }, // Column C
    { wch: 15 }, // Column C
    { wch: 15 }, // Column C
    { wch: 15 }, // Column C
    { wch: 30 }, // Column C
  ];

  const wb = utils.book_new();

  utils.book_append_sheet(wb, sheet, 'Date Range Report');
  writeFile(wb, 'Range.xlsx');
};
