import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ChevronLeft, ChevronRight, Clear, Search } from '@material-ui/icons';
import dateformat from 'dateformat';
import { withDevice } from '../device';
import { ALL_MONTHLY_REPORT } from '../../../utils/url';
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const EmptyFeedBack = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Typography variant="h4">No Data Found</Typography>
    </Box>
  );
};

const MonthlyDataTable = ({ data }) => {
  return (
    <div className="ag-theme-alpine" style={{ flex: 1, maxHeight: 430 }}>
      <AgGridReact rowData={data}>
        <AgGridColumn
          width={150}
          headerName="ID"
          field="id"
          filter={true}
        ></AgGridColumn>
        <AgGridColumn width={200} field="code"></AgGridColumn>
        <AgGridColumn
          width={200}
          headerName="Registration Number"
          field="registration_number"
        ></AgGridColumn>
        <AgGridColumn
          width={150}
          headerName="SIM Number"
          field="device_sim_number"
        ></AgGridColumn>
        <AgGridColumn width={150} field="model"></AgGridColumn>
        <AgGridColumn
          width={150}
          headerName="Day Count"
          field="day_count"
        ></AgGridColumn>
        <AgGridColumn
          width={150}
          headerName="Location Count"
          field="location_count"
        ></AgGridColumn>
        <AgGridColumn
          width={150}
          headerName="Distance (KM)"
          field="distance"
        ></AgGridColumn>
        <AgGridColumn
          width={250}
          headerName="Running Time"
          field="running_time"
        ></AgGridColumn>
        <AgGridColumn
          width={250}
          headerName="Congestion Time"
          field="congestion_time"
        ></AgGridColumn>
        <AgGridColumn
          width={250}
          headerName="Idle Time"
          field="idle_time"
        ></AgGridColumn>
      </AgGridReact>
    </div>
  );
};

const MonthlyReport = ({ deviceParam }) => {
  const { setTitle } = deviceParam;

  const [date, setDate] = useState(new Date());
  const [data, setData] = useState(null);
  const [original, setOriginal] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setTitle('Monthly Report of All Vehicles');
  }, []);

  useEffect(() => {
    let data = {
      year: date.getFullYear(),
      month: date.getMonth(),
    };

    axios
      .post(ALL_MONTHLY_REPORT, data)
      .then((response) => setOriginal(response.data))
      .catch((err) => console.log(err));
  }, [date]);

  useEffect(() => {
    setData(original);
  }, [original]);

  useEffect(() => {
    if (original) {
      setData(
        [...original].filter((x) => {
          return (
            x.id.toLowerCase().includes(search.toLowerCase()) ||
            x.code.toLowerCase().includes(search.toLowerCase()) ||
            x.device_sim_number.toLowerCase().includes(search.toLowerCase())
          );
        })
      );
    }
  }, [search]);

  const prevClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() - 1);
      return bbb;
    });
  };

  const nextClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() + 1);
      return bbb;
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const getTotalDistance = () => {
    let total = 0;

    if (original) {
      original.map((x) => {
        total = total + x.distance;
      });
    }

    return total.toFixed(2);
  };

  const getTotalDayCount = () => {
    let total = 0;

    if (original) {
      original.map((x) => {
        total = total + x.day_count;
      });
    }

    return total.toFixed(0);
  };

  return (
    <Box
      display="flex"
      style={{
        width: '99%',
        height: '96%',
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <Card style={{ width: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: '100%', height: '100%', padding: 10 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ padding: 10 }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="button">Total Distance</Typography>
              <Typography variant="body1">{getTotalDistance()} KM</Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="button">Total Day Count</Typography>
              <Typography variant="body1">{getTotalDayCount()} Days</Typography>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={prevClick}>
                <ChevronLeft color="secondary" />
              </IconButton>
              <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                Report on {dateformat(date, 'mmmm-yyyy')}
              </Typography>
              <IconButton onClick={nextClick}>
                <ChevronRight color="secondary" />
              </IconButton>
            </Box>

            <TextField
              placeholder="Search by ID and Code"
              onChange={handleChange}
              name="search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch('')}>
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {data &&
            (data.length === 0 ? (
              <EmptyFeedBack />
            ) : (
              <MonthlyDataTable data={data} />
            ))}
        </Box>
      </Card>
    </Box>
  );
};

export default withDevice(MonthlyReport);
