import React, { useEffect, useState } from 'react';
import { withDevice } from '../device';
import {
  Card,
  IconButton,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import UserDeviceAutoComplete from './daily-report/user_device_autocomplete';
import SharedUserDeviceAutoComplete from './daily-report/shared_user_devices_autocomplete';
import axios from 'axios';
import { RANGE_REPORT_URL } from '../../../utils/url';
import { MyDateTimePicker } from '../component/daily-report/date_picker';
import { RangeReportTable, formatDuration, getDate } from './virtual_table';
import dateformat from 'dateformat';
import { downloadRangeReport } from '../../../utils/download';

const IndeterminateCircularProgress = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </div>
  );
};

function RangeReport({ deviceParam }) {
  const { setTitle, fromSharedUser, device } = deviceParam;

  const [state, setState] = useState({
    device: { ...device },
    startDate: new Date(),
    endDate: new Date(),
    datas: [],
    err_message: '',
    show_progress: false,
  });

  const setStartDate = (date) => {
    setState((oldState) => ({ ...oldState, startDate: date }));
  };

  const setEndDate = (date) => {
    setState((oldState) => ({ ...oldState, endDate: date }));
  };

  const setDevice = (device) => {
    setState((old) => ({ ...old, device: device }));
  };

  const getTotalDistance = () => {
    let d = state.datas.reduce((acc, obj) => acc + obj.distance, 0);

    return (d / 1000).toFixed(2);
  };

  const getTotalRunningTime = () => {
    let t = state.datas.reduce((acc, obj) => acc + obj.running_time, 0);
    return formatDuration(t);
  };

  const handleGenerateClick = (e) => {
    setState((old) => ({ ...old, err_message: '', show_progress: true }));
    let data = {
      id: state.device.id,
      device_type: state.device.vehicle_type,
      start: state.startDate.toISOString(),
      end: state.endDate.toISOString(),
    };
    // console.log(data);
    // console.log(state.endDate.toISOString());

    if (state.startDate.getTime() >= state.endDate.getTime()) {
      return setState((old) => ({
        ...old,
        err_message: 'End Date Should Greater than Start Date',
      }));
    }

    axios
      .post(RANGE_REPORT_URL, data, { timeout: 180000 })
      .then((response) => {
        let output = response.data;
        if (output.status) {
          console.log(output.data);
          setState((old) => ({
            ...old,
            datas: output.data,
            show_progress: false,
          }));
        } else {
          setState((old) => ({
            ...old,
            err_message: output.message,
            show_progress: false,
          }));
        }
        // console.log(output);
      })
      .catch((err) => {
        setState((old) => ({ ...old, err_message: '', show_progress: false }));
        console.log(err);
      });
  };

  const downloadFile = () => {
    let datas = [...state.datas].map((x) => ({
      date: getDate(x._id),
      start_address: x.start_address,
      start: dateformat(x.start_time, 'hh:MM TT'),
      end: dateformat(x.end_time, 'hh:MM TT'),
      running: formatDuration(x.running_time),
      idle: formatDuration(x.idle_time),
      congestion: formatDuration(x.congestion_time),
      distance: (x.distance / 1000).toFixed(2),
      end_address: x.end_address,
    }));

    let info = [
      ['Report Title', 'Date-Range Report'],
      ['Report Date', new Date().toLocaleDateString()],
      ['Asset Name', state.device.registration_number],
      ['Asset IMEI', state.device.id],
      ['Report Start Time', state.startDate.toString()],
      ['Report End Time', state.endDate.toString()],
      ['Distance', getTotalDistance() + ' KM'],
      ['Running Time', getTotalRunningTime()],
    ];

    downloadRangeReport(datas, info);

    // console.log(datas);
  };

  useEffect(() => {
    setTitle('Date range Report');
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <Card square style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid gray',
                    padding: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 5,
                  }}
                >
                  <Typography variant="body1">Total Distance</Typography>
                  <Typography variant="caption">
                    {state.datas.length > 0
                      ? getTotalDistance() + ' KM'
                      : '0.00 KM'}
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid gray',
                    padding: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 5,
                  }}
                >
                  <Typography variant="body1">Running Time</Typography>
                  <Typography variant="caption">
                    {state.datas.length > 0
                      ? getTotalRunningTime()
                      : '0.00 HRS'}
                  </Typography>
                </div>
              </div>

              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                {fromSharedUser ? (
                  <SharedUserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                ) : (
                  <UserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                )}
                <MyDateTimePicker
                  selectedDate={state.startDate}
                  setSelectedDate={setStartDate}
                  label="Set Start Date"
                />

                <MyDateTimePicker
                  selectedDate={state.endDate}
                  setSelectedDate={setEndDate}
                  label="Set End Date"
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateClick}
                >
                  Generate
                </Button>

                {!state.err_message && state.datas.length > 0 && (
                  <IconButton onClick={downloadFile}>
                    <GetApp />
                  </IconButton>
                )}
              </div>
            </div>

            {state.err_message ? (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h4">{state.err_message}</Typography>
              </div>
            ) : (
              <div style={{ flex: 1, display: 'flex' }}>
                {state.show_progress ? (
                  <IndeterminateCircularProgress />
                ) : (
                  <RangeReportTable datas={state.datas} />
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default withDevice(RangeReport);
